import Typewriter from "typewriter-effect";

export default function TypeWriterComponent({ texts }) {
  return (
    <Typewriter
      onInit={(typewriter) => {

        typewriter.pauseFor(2200);

        texts.forEach((text, index) => {
          const isLastText = index === texts.length - 1;
          typewriter
            .typeString(text)
            .callFunction(() => {
              console.log(`Text ${index + 1} typed out!`);
            })
            .pauseFor(1750);
          if (!isLastText) {
            typewriter.deleteAll().pauseFor(1000);
          }
        });

        // .deleteAll()
        // .pauseFor(1250);
        // });
        typewriter.start();
      }}
      options={{
        autoStart: true,
        loop: false,
        cursor: "",
        delay: "100",
        deleteSpeed: "100",
      }}
    />
  );
}
