import React from "react";

const Home = () => {
  return (
    <>
      <div className="Home">
        {/* <iframe
          className="news-container"
          title="news"
          width="900"
          height="1000"
          src="https://rss.app/embed/v1/carousel/vM0c9baSl7Xo55lt"
          frameborder="0"
        ></iframe> */}
      </div>
    </>
  );
};

export default Home;
