import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Box.css";
import alexisProfilePic from "../../Assets/profiles/urwifeisbunnyRaw.png";
import jojonProfilePic from "../../Assets/profiles/JoJonRaw.png";
import fuzzworthProfilePic from "../../Assets/profiles/fuzzRaw.png";
import devinProfilePic from "../../Assets/profiles/devinRaw.png";
import strangeDarknessProfilePic from "../../Assets/profiles/strangeDarknessRaw.png";
import kerrisCookiesProfilePic from "../../Assets/profiles/kerrisCookiesRaw.png";
import peteQuestProfilePic from "../../Assets/profiles/PeteQuestRaw.png";
import tommyProfilePic from "../../Assets/profiles/tommyProfilePiccircle.png";

export const profiles = [
  {
    image: alexisProfilePic,
    name: "UrWifeIsBunny",
    title: "DJ",
    bio: "A creative soul with a deep passion for music, Alexis blends rhythmic beats with unique melodies to create unforgettable experiences on the dance floor. With an ear for innovation, Alexis is always exploring new sounds and pushing the boundaries of electronic music.",
    bandcamp: null,
    discord: null,
    facebook: "null",
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: "null",
    spotify: null,
    tiktok: "null",
    twitch: null,
    twitter: null,
    vimeo: null,
    website: null,
    youtube: null,
  },
  {
    image: fuzzworthProfilePic,
    name: "Fuzz Worth",
    title: "Producer/DJ/Engineer",
    bio: "Known for spinning the sickest tracks, Fuzz Worth electrifies crowds with an unmatched energy. His sets are a journey through sound, where every beat and drop is crafted to keep the audience moving and engaged from start to finish.",
    bandcamp: null,
    discord: null,
    facebook: "null",
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: "null",
    spotify: null,
    tiktok: null,
    twitch: null,
    twitter: null,
    vimeo: null,
    website: "null",
    youtube: "https://youtube.com/",
  },
  {
    image: jojonProfilePic,
    name: "JoJon",
    title: "DJ/Producer",
    bio: "A multifaceted artist, JoJon not only commands the DJ booth but also produces tracks that resonate with fans around the world. Avid gamer and streamer by day, JoJon seamlessly merges the worlds of gaming and music, creating a unique and immersive experience for his audience.",
    bandcamp: null,
    discord: null,
    facebook: "null",
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: "null",
    spotify: null,
    tiktok: null,
    twitch: "null",
    twitter: null,
    vimeo: null,
    website: null,
    youtube: null,
  },
  {
    image: peteQuestProfilePic,
    name: "Pete Quest",
    title: "DJ",
    bio: "An explorer of new worlds, Pete Quest takes his audience on a sonic adventure with each set. His eclectic taste and seamless mixing skills create a journey through various genres, making every performance a fresh and exciting experience.",
    bandcamp: null,
    discord: null,
    facebook: "null",
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: "null",
    spotify: null,
    tiktok: null,
    twitch: null,
    twitter: null,
    vimeo: null,
    website: null,
    youtube: null,
  },
  {
    image: devinProfilePic,
    name: "Devin Whitten",
    title: "Musician/Developer/Producer",
    bio: "A true innovator in the studio, Devin builds intricate soundscapes that captivate listeners. His music is a blend of artistry and technical prowess, where each track is a carefully crafted piece of sonic architecture designed to resonate with the soul.",
    bandcamp: null,
    discord: null,
    facebook: null,
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: "null",
    spotify: null,
    tiktok: null,
    twitch: "null",
    twitter: "null",
    vimeo: null,
    website: "null",
    youtube: "https://youtube.com/",
  },
  {
    image: strangeDarknessProfilePic,
    name: "Strange Darkness",
    title: "Zine",
    bio: "Our name says it all. If you like things that are Strange and/or dark, look no further. We are two long time friends inspired by the DIY nature of the punk rock, low-budget movies, and everything counter culture. We hope you will join us and our journey by reading our zine, buying our merch, and sharing your interests with our community we hope to build.",
    bandcamp: null,
    discord: null,
    facebook: "null",
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: null,
    spotify: null,
    tiktok: "null",
    twitch: null,
    twitter: null,
    vimeo: null,
    website: "null",
    youtube: "https://youtube.com/",
  },
  {
    image: kerrisCookiesProfilePic,
    name: "Kerri's Cookies",
    title: "Baker",
    bio: "Kerri’s Cookies is all about making life sweeter with a healthier twist. As a master baker, Kerri is dedicated to crafting delightful treats using only the finest organic and wholesome ingredients. Each cookie is a blend of indulgence and nourishment, ensuring that every bite is as good for your body as it is for your soul. Whether you're craving something sweet or looking for a guilt-free snack, Kerri’s Cookies offers the perfect balance of flavor and health.",
    bandcamp: null,
    discord: null,
    facebook: null,
    instagram: "null",
    mixcloud: null,
    patreon: null,
    pinterest: null,
    reverbnation: null,
    snapchat: null,
    soundcloud: null,
    spotify: null,
    tiktok: "null",
    twitch: null,
    twitter: "null",
    vimeo: null,
    website: "null",
    youtube: null,
  },
  {
    image: tommyProfilePic,
    name: "Tommy Garrett",
    title: "Musician/DJ/Producer",
    bio: "Capturing moments through music, Tommy’s productions are a reflection of his deep connection to sound and emotion. Each track tells a story, blending heartfelt melodies with powerful rhythms to create a lasting impact on the listener.",
    bandcamp: "null",
    discord: "null",
    facebook: "null",
    instagram: "null",
    mixcloud: "null",
    patreon: "null",
    pinterest: "null",
    reverbnation: "null",
    snapchat: "null",
    soundcloud: "null",
    spotify: "null",
    tiktok: "null",
    twitch: "null",
    twitter: "null",
    vimeo: "null",
    website: "null",
    youtube: "null",
  },
];




function stepFunction( x, range ) {
  
  return Math.abs(x) > range ? -1 : 1;
}


const ProfileWrapper = () => {
  const [openIndex, setOpenIndex] = useState(null);
  // const [positions, setPositions] = useState(
  //   profiles.map(() => ({ x: 0, y: 0 }))
  // );

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // const generateMovementStep = () => ({
  //   x: Math.random() * 2 - 1,
  //   y: Math.random() * 2 - 1,
  // });

  // useEffect(() => {
  //   const moveProfiles = () => {
  //     setPositions((prevPositions) =>
  //       prevPositions.map((pos) => {
  //         const step = generateMovementStep();
  //         return {
  //           x: pos.x + stepFunction( pos.x, 8 ) * step.x,
  //           y: pos.y + stepFunction( pos.y, 8 ) * step.y,
  //         };
  //       })
  //     );
  //     setTimeout(() => {
  //       requestAnimationFrame(moveProfiles);
  //     }, 100
  //   );
  //   };

  //   moveProfiles();
  // }, []);

  
  return (
    <>
      <h2 className="queryHeading">The Line Up</h2>
      <div className="profileWrapper">
        {profiles.map((profile, index) => (
          <div
            key={index}
            className={`box ${openIndex === index ? "flip" : ""}`}
            onClick={() => handleToggle(index)}
            // style={{
              // transform: `translate(${positions[index].x}px, ${positions[index].y}px)`,
              // transition: "transform 10s linear",
            // }}
          >
            <div className="box-inner">
              <div className="box-front">
                <img src={profile.image} alt="Profile" className="box-image" />
              </div>
              <div className="box-back">
                <Link
                  to={`/profile/${encodeURIComponent(
                    profile.name.replace(/ /g, "")
                  )}`}
                  className="profile-button"
                >
                  <p>{profile.name}</p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProfileWrapper;