import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { profiles } from "./ProfileWrapper";
import { Link } from "react-router-dom";
import "./Profile.css";

const Profile = () => {
  const { name } = useParams();

  const profile = profiles.find(
    (profile) => profile.name.replace(/ /g, "") === name
  );

  useEffect(() => {
    if (profile) {
      const imageElement = document.querySelector(".image-rotate");
      if (imageElement) {
        const handleMouseEnter = () => imageElement.classList.add("rotating");
        const handleTransitionEnd = () =>
          imageElement.classList.remove("rotating");

        imageElement.addEventListener("mouseenter", handleMouseEnter);
        imageElement.addEventListener("transitionend", handleTransitionEnd);

        // Clean up event listeners on component unmount
        return () => {
          imageElement.removeEventListener("mouseenter", handleMouseEnter);
          imageElement.removeEventListener(
            "transitionend",
            handleTransitionEnd
          );
        };
      }
    }
  }, [profile]);

  if (!profile) {
    return <p>Profile not found</p>;
  }

  return (
    <>
      <Link className="backToProfiles" to={"/lineup"} />
      <div className="profile-container">
      <div className="profile-card">
        <div className="gridName">
          <h1 className="profile-name">{profile.name}</h1>
          <h2 className="profile-title">{profile.title}</h2>
        </div>
        <div className="gridImage">
          <img
            src={profile.image}
            alt={profile.name}
            className="profile-image image-rotate"
          />
        </div>
        <div className="gridBio">
          <p className="profile-bio">{profile.bio}</p>
        </div>
        <div className="gridSocial">
          <div className="social-links">
            {profile.bandcamp && (
              <a
                href={profile.bandcamp}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Bandcamp
              </a>
            )}
            {profile.discord && (
              <a
                href={profile.discord}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Discord
              </a>
            )}
            {profile.facebook && (
              <a
                href={profile.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Facebook
              </a>
            )}
            {profile.instagram && (
              <a
                href={profile.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Instagram
              </a>
            )}
            {profile.mixcloud && (
              <a
                href={profile.mixcloud}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Mixcloud
              </a>
            )}
            {profile.patreon && (
              <a
                href={profile.patreon}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Patreon
              </a>
            )}
            {profile.pinterest && (
              <a
                href={profile.pinterest}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Pinterest
              </a>
            )}
            {profile.reverbnation && (
              <a
                href={profile.reverbnation}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                ReverbNation
              </a>
            )}
            {profile.snapchat && (
              <a
                href={profile.snapchat}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Snapchat
              </a>
            )}
            {profile.soundcloud && (
              <a
                href={profile.soundcloud}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Soundcloud
              </a>
            )}
            {profile.spotify && (
              <a
                href={profile.spotify}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Spotify
              </a>
            )}
            {profile.tiktok && (
              <a
                href={profile.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Tiktok
              </a>
            )}
            {profile.twitch && (
              <a
                href={profile.twitch}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Twitch
              </a>
            )}
            {profile.twitter && (
              <a
                href={profile.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Twitter
              </a>
            )}
            {profile.vimeo && (
              <a
                href={profile.vimeo}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Vimeo
              </a>
            )}
            {profile.website && (
              <a
                href={profile.website}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Website
              </a>
            )}
            {profile.youtube && (
              <a
                href={profile.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                YouTube
              </a>
            )}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Profile;
