import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../Assets/purple-logo.png";

import "../App.css";

const MainLogo = ({ onClick }) => {
  // const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  return (
    <>
      <Link to="/home">
        <img
          onClick={onClick}
          src={LogoImage}
          alt="Data Lair"
          className="Image"
        />
      </Link>
    </>
  );
};

export default MainLogo;
