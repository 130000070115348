import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import Query from "./Pages/Query";
import Stream from "./Pages/Stream";
import Connect from "./Components/Connect";
import WarpScene from "./Components/warp/Warp.js";
import Layout from "./Layout";
import Profile from "./Components/profiles/Profile";
import "./App.css";

const App = () => {
  // const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  // const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const handleLogoClick = () => {
    // setIsHeaderVisible(true); // Update state to show header
    // setIsNavbarVisible(true);
    // setIsHeaderVisible(true);

    console.log("Logo Click");
    // console.log({isNavbarVisible});
  };

  return (
    <>
      <Router>
        <WarpScene />
        <Layout>
          <Routes>
            <Route path="/" element={<Landing onClick={handleLogoClick} />} />
            <Route path="/home" element={<Home />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/lounge" element={<Stream />} />
            <Route path="/lineup" element={<Query />} />
            <Route path="/profile/:name" element={<Profile />} />{" "}
          </Routes>
        </Layout>
      </Router>
    </>
  );
};

export default App;
