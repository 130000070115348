import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';


// import Navbar from "./Components/Navbar";

import Header from "./Components/Navigation/Header";
import Navbar from "./Components/Navigation/Navbar";
// import MainLogo from "./Components/Logo";


const Layout = ({children}) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  // const handleLogoClick = () => {
  //   // setIsHeaderVisible(true); // Update state to show header
  //   setIsNavbarVisible(true);
  // };
  const location = useLocation();
  useEffect(() => {
    // Example: Hide the header and navbar on the /login route
    const hiddenRoutes = ["/"];
    
    if ( hiddenRoutes.includes( location.pathname)) {
      setIsHeaderVisible(false);
      setIsNavbarVisible(false);
    } else {
      setIsHeaderVisible(true);
      setIsNavbarVisible(true);
    }
    }, [location]);
  return (
    <div  className="layout-container">

       {isHeaderVisible && <Header /> } 
      <main>{children}</main>
      {isNavbarVisible && <Navbar />} 

    </div>
  );
};

export default Layout;