import React from "react";
import SignIn from "./Contact";

const Connect = () => {
  return (
    <>
      <SignIn />
    </>
  );
};

export default Connect;