import { useEffect, useRef } from "react";
import { Color, Object3D, Vector2, Vector3, Matrix4 } from "three";

import {
  Bloom,
  ChromaticAberration,
  EffectComposer,
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { Sparkles } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
// export interface SceneProps {}
// import { Perf } from 'r3f-perf'

const COUNT = 250;
const Z_BOUNDS = 800;
const MAX_SPEED_FACTOR = 1;
const MAX_SCALE_FACTOR = 50;
const XY_BOUNDS = 200;
const CHROMATIC_ABBERATION_OFFSET = 0.001;
function getRandomColor() {
  return new Color("#" + Math.random().toString(16).substr(-6));
}

function generatePos() {
  return (Math.random() - 0.5) * XY_BOUNDS;
}

const RotatingStars = () => {
  // Variables
  // const velocity = Math.random(1)/100;
  const randomNumber = Math.random(1) / 4e3;
  const stars = useRef();
  const starsCount = 50; // Number of stars
  // Generate random colors for each star
  // const colors = Array.from({ length: starsCount }, getRandomColor);
  const speedArray = Array.from({ length: starsCount }, () => 0.001);
  // const colorArray = Array.from({ length: starsCount }, () => 2.0);
  // const redArray = Array.from({ length: starsCount }, () => "red");

  // const starColor = new Color('0xffffff');

  // const colorArray = Array.from({ length: starsCount }, getRandomColor );

  const dumbColor = getRandomColor();

  // handler
  useFrame(() => {
    stars.current.rotation.x = stars.current.rotation.y += randomNumber; //0.00015
    //   stars.current.position.z += 0.0025;

    //   tempPos.z = tempPos.z > Z_BOUNDS ? -Z_BOUNDS : tempPos.z + 0.0025;
  });

  // return <Stars ref={stars}
  //   radius={500}
  //   factor={25}

  //   count={starsCount}
  //   color="0x0000FF"
  // />
  // return <Sparkles
  //   ref={stars}
  //   /** Number of particles (default: 100) */
  //   count = {starsCount}
  //   /** Speed of particles (default: 1) */
  //   // speed?: number | Float32Array
  //   speed = {speedArray}
  //   /** Opacity of particles (default: 1) */
  //   // opacity?: number | Float32Array
  //   /** Color of particles (default: 100) */
  //   // color?: THREE.ColorRepresentation | Float32Array
  //   color={dumbColor}
  //   /** Size of particles (default: randomized between 0 and 1) */
  //   // size?: number | Float32Array
  //   /** The space the particles occupy (default: 1) */
  //   // scale?: number | [number, number, number] | THREE.Vector3
  //   size = {2}
  //   scale = {8}
  //   /** Movement factor (default: 1) */
  //   // noise?: number | [number, number, number] | THREE.Vector3 | Float32Array
  //   noise = {speedArray}
  // />

  return (
    <>
      <Sparkles
        ref={stars}
        count={starsCount}
        speed={speedArray}
        color={dumbColor}
        size={1.0}
        scale={3}
        noise={0.15}
      />
      {/* <Sparkles ref={stars} count = {starsCount} speed = {speedArray}color={dumbColor} size = {2} scale = {8} noise = {speedArray} />
      <Sparkles ref={stars} count = {starsCount} speed = {speedArray}color={dumbColor} size = {2} scale = {8} noise = {speedArray} /> */}
    </>
  );
};

const InstancedMeshComponent = () => {
  const ref = useRef();
  const effectsRef = useRef();

  const cameraRef = useRef();

  const temp = new Matrix4();
  const tempPos = new Vector3();

  // const tempScale = new Vector3();
  const tempObject = new Object3D();
  const tempColor = new Color();

  useEffect(() => {
    if (!ref.current) return;

    const t = new Object3D();
    let j = 0;
    for (let i = 0; i < COUNT * 3; i += 3) {
      t.position.x = generatePos();
      t.position.y = generatePos();
      t.position.z = (Math.random() - 0.5) * Z_BOUNDS;
      t.updateMatrix();
      ref.current.setMatrixAt(j++, t.matrix);
    }
  }, []);

  useFrame((state, delta) => {
    const camera = cameraRef.current;
    //  console.log(camera.position.x);
    if (!ref.current) return;

    if (camera) {
      // Adjust rotation speed and direction as needed
      const rotationSpeed = 0.05;
      camera.position.x = Math.cos(state.clock.elapsedTime * rotationSpeed) * 5;
      camera.position.z = Math.sin(state.clock.elapsedTime * rotationSpeed) * 5;
      camera.lookAt(0, 0, 0); // Make the camera always look at the center of the scene
    }

    const velocity = Math.sin((state.clock.elapsedTime * 1) / 10) * 1.5;

    const cosVelocity = Math.sin((state.clock.elapsedTime * 1) / 10) * 1.0;

    for (let i = 0; i < COUNT; i++) {
      ref.current.getMatrixAt(i, temp);

      // update scale
      // tempObject.scale.set(1, 1, Math.max(1, velocity * MAX_SCALE_FACTOR));

      tempObject.scale.set(0.5, 0.5, velocity * MAX_SCALE_FACTOR);

      // update position
      tempPos.setFromMatrixPosition(temp);
      if (tempPos.z > Z_BOUNDS / 2) {
        tempPos.z = -Z_BOUNDS / 2;
      } else {
        //   tempPos.z += Math.max(delta, Math.pow(0.5, state.clock.elapsedTime));
        tempPos.z += Math.max(0.1, velocity * MAX_SPEED_FACTOR);
      }
      tempObject.position.set(tempPos.x, tempPos.y, tempPos.z);
      tempObject.updateMatrix();
      ref.current.setMatrixAt(i, tempObject.matrix);

      // update and apply color
      if (tempPos.z > 0) {
        tempColor.r = tempColor.g = tempColor.b = 1;
      } else {
        tempColor.r =
          tempColor.g =
          tempColor.b =
            1 - tempPos.z / (Z_BOUNDS / 1);
      }
      ref.current.setColorAt(i, tempColor);
    }
    ref.current.instanceMatrix.needsUpdate = true;
    if (ref.current.instanceColor) ref.current.instanceColor.needsUpdate = true;

    if (!effectsRef.current) return;
    //   effectsRef.current.offset.x = Math.max( 1, Math.pow(1.01, state.clock.elapsedTime /10 ) * CHROMATIC_ABBERATION_OFFSET);
    effectsRef.current.offset.x = cosVelocity * CHROMATIC_ABBERATION_OFFSET;
    effectsRef.current.offset.y = cosVelocity * CHROMATIC_ABBERATION_OFFSET;
  });

  return (
    <>
      <perspectiveCamera ref={cameraRef} />

      <instancedMesh ref={ref} args={[null, null, COUNT]} matrixAutoUpdate>
        <sphereGeometry args={[0.1]} />
        <meshBasicMaterial color="white" toneMapped={false} />
      </instancedMesh>

      <EffectComposer>
        <Bloom luminanceThreshold={0.8} mipmapBlur />
        <ChromaticAberration
          ref={effectsRef}
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={
            new Vector2(
              CHROMATIC_ABBERATION_OFFSET,
              CHROMATIC_ABBERATION_OFFSET
            )
          }
        />
      </EffectComposer>
    </>
  );
};

const WarpScene = () => {
  return (
    <div className="stars">
      <Canvas camera={{ position: [0, 0, 5] }}>
        {/* <Perf position="top-right" chart={ chartParams }  /> */}
        <RotatingStars />
        <RotatingStars />

        <InstancedMeshComponent />
      </Canvas>
    </div>
  );
};

export default WarpScene;