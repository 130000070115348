import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="Navbar">
        <h1>
          <Link to="/connect">Connect</Link>
        </h1>
        <h1>
          <Link to="/lounge">Lounge</Link>
        </h1>
        <h1>
          <Link to="/lineup">Line up</Link>
        </h1>
      </div>
    </>
  );
};

export default Navbar;
