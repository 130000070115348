import React from "react";
import { Link } from "react-router-dom";

import LogoImageSmall from "../../Assets/dl-white-shadow.png";
import "./Header.css";

const Header = () => {
  // const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  return (
    <header className="header">
      <Link to="/">
        <img src={LogoImageSmall} alt="Data Lair" className="nav-logo" />
      </Link>
    </header>
  );
};

export default Header;