import React from "react";

import { TwitchPlayer } from "react-twitch-embed";

function Twitch() {
  return (
    <div className="twitch-container">
      <TwitchPlayer
        allowFullscreen
        channel="data_lair"
        width="80%"
        height="100%"
        darkMode
        onAuthenticate={function noRefCheck() {}}
        onVideoPause={function noRefCheck() {}}
        onVideoPlay={function noRefCheck() {}}
        onVideoReady={function noRefCheck() {}}
      />
    </div>
  );
}

export default Twitch;
