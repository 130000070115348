import React from "react";
import ProfileWrapper from "../Components/profiles/ProfileWrapper";

const Query = () => {
  return (
    <>
      <div className="scroll-container">
        <ProfileWrapper />
      </div>
    </>
  );
};

export default Query;
