import React from "react";
import MainLogo from "../Components/Logo";
import TypeWriterComponent from "../Components/typewriter";

import Discord from "../Assets/icon_clyde_white_RGB.png";
import Instagram from "../Assets/Instagram_Glyph_White.png";
import Twitch from "../Assets/glitch_flat_white.png";
import YouTube from "../Assets/youtube-icon.png";

import "../App.css";
const data = ["A digital archive.", "A platform for artists and creators."];

const Landing = ({ onClick }) => {
  return (
    <>
      <div className="Landing">
        <MainLogo onClick={onClick} />

        <div className="description">
          <TypeWriterComponent texts={data} />
        </div>
        {/* <div className="Home"></div> */}

        <div className="socialIcons">
          <a
            href="https://discord.gg/RkeBfGZQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={Discord} alt="Discord" />
          </a>
          <a
            href="https://www.instagram.com/data_lair/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={Instagram} alt="Instagram" />
          </a>
          <a
            href="https://www.twitch.tv/data_lair"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={Twitch} alt="Twitch" />
          </a>
          <a
            href="https://www.youtube.com/@DataLair"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="youtubeIcon" src={YouTube} alt="YouTube" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Landing;
