import React, { useEffect, useState } from "react";

import "../App.css";

function SoundCloud() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <div className="soundcloud-container">

        <iframe
          title="soundcloud"
          className="soundcloud"
          width="100%"
          height="100%"
          autoPlay={true}
          frameBorder={"true"}
          style={{ color: "#000000" }}
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1788313338&color=%234b2a92&show_artwork=true&auto_play=false&hide_related=false&show_comments=true&show_teaser=false&show_user=true"
        ></iframe>
      </div>
    </>
  );
}

export default SoundCloud;
