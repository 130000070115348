import React from "react";
import SoundCloud from "../Components/SoundCloud";
import Twitch from "../Components/Twitch";

const Stream = () => {
  return (
    <>
      <h2 className="loungeHeading">The Lounge</h2>
      <div className="media-container">
        <div>
          <SoundCloud />
        </div>
        <div>
          <Twitch />
        </div>
      </div>
    </>
  );
};

export default Stream;
